.max-w-100px {
  max-width: 100px;
}

.ml-auto {
  margin-left: auto;
}

.w-10 {
  width: 10%;
}
.w-90 {
  width: 90%;
}

.max-w-250px {
  max-width: 250px;
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .h-sm-100 {
    height: 100% !important;
  }

  .h-sm-75 {
    height: 75% !important;
  }

  .h-sm-50 {
    height: 50% !important;
  }

  .h-sm-25 {
    height: 25% !important;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .h-md-100 {
    height: 100% !important;
  }

  .h-md-75 {
    height: 75% !important;
  }

  .h-md-50 {
    height: 50% !important;
  }

  .h-md-25 {
    height: 25% !important;
  }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .h-lg-100 {
    height: 100% !important;
  }

  .h-lg-75 {
    height: 75% !important;
  }

  .h-lg-50 {
    height: 50% !important;
  }

  .h-lg-25 {
    height: 25% !important;
  }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-25 {
    width: 25% !important;
  }

  .h-xl-100 {
    height: 100% !important;
  }

  .h-xl-75 {
    height: 75% !important;
  }

  .h-xl-50 {
    height: 50% !important;
  }

  .h-xl-25 {
    height: 25% !important;
  }
}
