/* * * * * * * * * * * * * * * * * * Global Styles * * * * * * * * * * * * * * * * * */
html,
body {
  font-family: $font-roboto;
  height: 100%;
  background-color: $background-1;
  font-size: 18px;
}

/* * * * * * * * * * * * * * * * * * Layout * * * * * * * * * * * * * * * * * */

#Wrapper {
  min-height: 100%;
  aside {
    background-color: $white;
    width: 80px;
    height: 100%;
  }
  main {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 100%;
  }
}

a:hover {
  text-decoration: none;
}

/* * * * * * * * * * * * * * * * * * Titles * * * * * * * * * * * * * * * * * */

h1,
h2,
h3,
h4,
h5 {
  font-family: $font-aleo;
}

.max-content-width {
  max-width: $max-content-width;
  margin: 0 auto;
}

.max-content-width-small {
  max-width: $max-content-width-small;
  margin: 0 auto;
}

/*** HELPERS  ****/

.bg-white {
  background-color: $white;
}

.tig-label {
  color: $tig-grey;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  &.tig-label-large {
    font-size: 18px;
  }
}

/*sizes*/

.max-w-260 {
  max-width: 260px;
}

.fs-7 {
  font-size: 0.8rem;
}

.text-blue {
  color: $tig-blue;
}
.text-light-gray {
  color: $tig-grey;
}
.text-text {
  color: $tig-text;
}

.text-success {
  color: $tig-edited;
}

